
window.addEventListener('load', function () {
    DEBUG && console.info('Page Header Component *--> Init');

    //var elements = document.querySelectorAll('[data-component="mediaScroller"]');

    //elements.forEach(element => {
    //    var congregationId = element.getAttribute('data-congregationId');
    //    var seriesTypeId = element.getAttribute('data-seriesTypeId');
    //    var detailPageUrl = element.getAttribute('data-seriesDetailUrl');

    //    DEBUG && console.info('**************************************************');
    //    DEBUG && console.info(`Congregation:${congregationId}`);
    //    DEBUG && console.info(`Series Type:${seriesTypeId}`);
    //    DEBUG && console.info(`Element ID:${element.id}`);
    //    DEBUG && console.info(`Series Detail Url:${detailPageUrl}`);
    //    DEBUG && console.info('**************************************************');

    //    SermonModule.InitSeriesScroller(element.id, congregationId, seriesTypeId, detailPageUrl);
    //});
});